
import { Options, Vue } from 'vue-class-component'

import api from '@/api'
import { toDate } from '../utils/common'
import store from '../store'
import { ElMessage } from 'element-plus'

@Options({
  components: {}
})
export default class news extends Vue {
  toDate = toDate;
  table = [];
  dialogVisible = false;
  d: any = {};
  loading = false

  pageIndex = 1;
  pageSize = 20;
  pageCount = 0;

  created () {
    this.getTable()
  }

  changePage (val: number) {
    this.pageIndex = val
    this.getTable()
  }

  getTable () {
    this.loading = true
    api
      .getCompanyNews({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        ...store.getters['company/get']
      })
      .then((res: any) => {
        this.table = res.data
        this.pageCount = res.page.totalRecords
      }).finally(() => {
        this.loading = false
      })
  }

  ckInfo (row: any) {
    api
      .getCompanyNewsDetail({
        id: row.id,
        ...store.getters['company/get']
      })
      .then((data) => {
        if (!data) {
          ElMessage({ type: 'warning', message: '没有详情,请查看新闻来源' })
          return
        }
        this.d = data
        this.dialogVisible = true
      })
  }
}
